import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H1 from 'components/new/typography/h1'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import Checklist from 'components/new/checklist'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const CombineInspectionsPage = props => {
  const {
    data: { mainImage, muaImage, multiUnitDiscountsImage, partsWarrantyImage },
  } = props
  return (
    <Layout kind='white'>
      <Helmet>
        <title>Combine Service Inspections | Hutson Inc</title>
      </Helmet>

      <Content kind='full'>
        <H1>Combine Inspection Program</H1>
        <TopGrid>
          <TopGridColumn>
            <Spacer size='m' />
            <H2>199-Point Inspection</H2>
            <Spacer size='s' />
            <StyledChecklist>
              <P>Inspect and set for next crop</P>
              <P>
                Inspect feederhouse and chains, sprockets, bearings, reverser, cylinder, rasp bars,
                concave and rotor
              </P>
              <P>Inspect all belts, drives and bearings</P>
              <P>Inspect shoe augers and bearings</P>
              <P>Inspect cleaning shoe bushings and hangers</P>
              <P>Inspect all housings and auger flightings</P>
            </StyledChecklist>
            <Spacer size='s' />
            <H4 as='p'>Get in touch with your local Hutson service shop for pricing.</H4>
            <Spacer size='s' />
            <ButtonContainer>
              <Button as={Link} to='/locations/' ghost color='green'>
                Find your nearest location
              </Button>
            </ButtonContainer>
          </TopGridColumn>
          <TopGridColumn>
            <GatsbyImage
              image={mainImage.childImageSharp.gatsbyImageData}
              alt='John Deere combine'
            />
          </TopGridColumn>
        </TopGrid>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2>Why Hutson?</H2>
          <Spacer size='m' />
          <Grid>
            <Column>
              <GatsbyImage image={multiUnitDiscountsImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Multi-Unit Discount</H3>
                <P>
                  Have multiple units that need to be inspected? Schedule a multi-unit inspection
                  with your local service manager to qualify for additional discounts!
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={Link}
                    to='/service/inspections#multi-unit-inspections'
                    ghost
                    color='green'
                    aria-label='Learn more about Multi-Unit Inspections'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={partsWarrantyImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>12-Month Parts Warranty</H3>
                <P>
                  All new and remanufactured John Deere parts come with a 12 month warranty when
                  installed by a Hutson technician.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={Link}
                    to='/service/inspections#parts-warranty'
                    ghost
                    color='green'
                    aria-label='Learn more about our parts warranty'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={muaImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Financing Options</H3>
                <P>
                  Keep your business moving forward with a Multi-Use Account from John Deere
                  Financial! We offer No Interest, No Payment plans to help you get what you need,
                  when you need it.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={OutboundLink}
                    href='https://www.deere.com/en/finance/financing/multi-use-account/'
                    ghost
                    color='green'
                    aria-label='Learn more about John Deere Multi-Use Account'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
        </Content>
      </LightSection>
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const TopGrid = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    ${clearfix}
  }
`

const TopGridColumn = styled.div`
  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/2')}

    :first-child {
      align-self: flex-start;
    }

    :last-child {
      align-self: flex-end;
    }
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const StyledChecklist = styled(Checklist)`
  p {
    margin: 0;
    line-height: inherit;
  }

  @media (min-width: 600px) and (max-width: 899px), (min-width: 1100px) {
    columns: 2;
  }
`

export const pageQuery = graphql`
  query combineInspectionsPageQuery {
    mainImage: file(relativePath: { eq: "service/inspections/combines.jpg" }) {
      ...SharpImage800
    }
    muaImage: file(relativePath: { eq: "service/inspections/multi-use-account.jpg" }) {
      ...SharpImage800
    }
    multiUnitDiscountsImage: file(
      relativePath: { eq: "service/inspections/multi-unit-discount.jpg" }
    ) {
      ...SharpImage800
    }
    partsWarrantyImage: file(relativePath: { eq: "service/inspections/parts-warranty.jpg" }) {
      ...SharpImage800
    }
  }
`

export default CombineInspectionsPage
